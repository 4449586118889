import React from "react";
import { PlusIcon } from "../../data/Data";
import { Link } from "react-router-dom";

const To27 = ({ text, onClick, url }) => {
  return (
    <Link to={url}>
      <div
        onClick={onClick}
        className="flex items-center theme text-xs font-medium cursor-pointer"
      >
        <PlusIcon color="var(--themeColor)" size={12} />
        <div>{text}</div>
      </div>
    </Link>
  );
};

export default To27;
