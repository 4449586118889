export const sendEmail = async ({ from, recipient, subject, message }) => {
  let success = false;
  let result;

  // Keep retrying until success is true
  while (!success) {
    try {
      const response = await fetch(
        "https://us-central1-peppermodel-com.cloudfunctions.net/sendEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from,
            recipient,
            subject,
            message,
          }),
        }
      );

      // Check if the response is successful (status code 2xx)
      if (!response.ok) {
        throw new Error(`Failed to send email: ${response.statusText}`);
      }

      const resultJson = await response.json();
      console.log(resultJson.message);

      // Set success to true to break the loop
      success = true;
      result = { success: true, message: resultJson.message };
    } catch (error) {
      console.error("Error sending email, retrying:", error.message);

      // Optional: Add a delay between retries to avoid spamming the server
      await new Promise((resolve) => setTimeout(resolve, 2000)); // 2-second delay
    }
  }

  return result;
};

export const sendSms = async ({ from, recipient, message }) => {
  let success = false;
  let result;

  // Keep retrying until success is true
  while (!success) {
    try {
      const response = await fetch(
        "https://us-central1-peppermodel-com.cloudfunctions.net/sendSms",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from,
            recipient,
            message,
          }),
        }
      );

      // Check if the response is successful (status code 2xx)
      if (!response.ok) {
        throw new Error(`Failed to send sms: ${response.statusText}`);
      }

      const resultJson = await response.json();
      console.log(resultJson.message);

      // Set success to true to break the loop
      success = true;
      result = { success: true, message: resultJson.message };
    } catch (error) {
      console.error("Error sending sms, retrying:", error.message);

      // Optional: Add a delay between retries to avoid spamming the server
      await new Promise((resolve) => setTimeout(resolve, 2000)); // 2-second delay
    }
  }

  return result;
};

export const sendWhatsapp = async ({ from, recipient, message }) => {
  let success = false;
  let result;

  // Keep retrying until success is true
  while (!success) {
    try {
      const response = await fetch(
        "https://us-central1-peppermodel-com.cloudfunctions.net/sendWhatsapp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from,
            recipient,
            message,
          }),
        }
      );

      // Check if the response is successful (status code 2xx)
      if (!response.ok) {
        throw new Error(`Failed to send  Whatsapp: ${response.statusText}`);
      }

      const resultJson = await response.json();
      console.log(resultJson.message);

      // Set success to true to break the loop
      success = true;
      result = { success: true, message: resultJson.message };
    } catch (error) {
      console.error("Error sending  Whatsapp, retrying:", error.message);

      // Optional: Add a delay between retries to avoid spamming the server
      await new Promise((resolve) => setTimeout(resolve, 2000)); // 2-second delay
    }
  }

  return result;
};
