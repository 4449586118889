import React from 'react'

const To9 = ({title, desc, sm}) => {
    return (
      <div className='mb-2 text-center mt-3 px-8'>
          <div className={`font-semibold ${sm ? 'text-xl' : 'text-3xl'} theme`}>{title}</div>
          <div className={`${sm ? 'text-xs' : 'text-sm'} mt-1 btc`}>{desc}</div>
      </div>
    )
  }

export default To9