import "./App.css";
import "./styles/General.css";
import "./styles/Resp.css";
import "react-toastify/dist/ReactToastify.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import AppRouter from "./components/AppRouter";
import ThemeProvider from "./providers/ThemeProvider";
import { ThemeContextProvider } from "./contexts/ThemeContext";
import AuthContextProvider from "./contexts/AuthContext";
import { GeneralBrainProvider } from "./controller/Brain";
import { DataProvider } from "./contexts/DataContext";
import { useContext, useEffect } from "react";
import { ThemeContext } from "@emotion/react";

function App() {
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    // Conditionally import SweetAlert stylesheets based on theme
    if (theme === "light") {
      import("sweetalert2/dist/sweetalert2.min.css");
    } else {
      import("@sweetalert2/theme-dark/dark.scss");
    }
  }, [theme]);
  return (
    <div className="App">
      <AuthContextProvider>
        <DataProvider>
          <GeneralBrainProvider>
            <ThemeContextProvider>
              <ThemeProvider>
                <div className="container-app">
                  <AppRouter />
                </div>
              </ThemeProvider>
            </ThemeContextProvider>
          </GeneralBrainProvider>
        </DataProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
