import React, { useState } from "react";
import Sec1 from "./Sec1";
import Sec2 from "./Sec2";
import Sec3 from "./Sec3";
import Sec4 from "./Sec4";
import { Filter1Icon } from "../../data/Data";
import To8sa from "../cards/To8sa";
import { useGeneralBrain } from "../../controller/Brain";

const ShowFilter = ({ setshowfilter, filter, setfilter }) => {
  console.log(filter, "filter here");
  const [dropdowns, setDropdowns] = useState(Array(25).fill(false));

  const toggleDropdown = (index) => {
    const updatedDropdowns = [...dropdowns];
    updatedDropdowns[index] = !updatedDropdowns[index];

    for (let i = 0; i < updatedDropdowns.length; i++) {
      if (i !== index) {
        updatedDropdowns[i] = false;
      }
    }

    setDropdowns(updatedDropdowns);
  };

  const handleSelect = (value, name) => {
    setfilter((prevFilter) => ({
      ...prevFilter,
      [name]: {
        ...prevFilter[name],
        [value]: !prevFilter[name][value], // Toggle the checkbox value
      },
    }));
  };

  const isButtons = [
    "Etnia",
    "Cabelo",
    "Tatuagens",
    "Piercings",
    "Silicone",
    "Cor dos olhos",
  ];

  const isRangeInput = ["Peso", "Altura", "Tamanho do pé"];

  const isButtons1 = [
    "Fumante",
    "Comportamento sexual",
    "Atende",
    "Clientes em conjunto",
    "Idioma",
  ];

  // Handler to update value
  const handleValueChange = (event, newValue) => {
    setfilter((prevFilter) => ({
      ...prevFilter,
      value: { start: newValue[0], end: newValue[1] },
    }));
  };

  // Handler to update age
  const handleAgeChange = (event, newValue) => {
    setfilter((prevFilter) => ({
      ...prevFilter,
      age: { start: newValue[0], end: newValue[1] },
    }));
  };

  const handleValueChange1 = (event, newValue) => {
    setfilter((prevFilter) => ({
      ...prevFilter,
      Peso: { start: newValue[0], end: newValue[1] },
    }));
  };

  const handleValueChange2 = (event, newValue) => {
    setfilter((prevFilter) => ({
      ...prevFilter,
      Altura: { start: newValue[0], end: newValue[1] },
    }));
  };

  const handleValueChange3 = (event, newValue) => {
    setfilter((prevFilter) => ({
      ...prevFilter,
      "Tamanho do pé": { start: newValue[0], end: newValue[1] },
    }));
  };

  const isRangeInputChange = [handleValueChange1, handleValueChange2, handleValueChange3 ];

  // Handler to toggle 'review'
  const handleReviewToggle = () => {
    setfilter((prevFilter) => ({
      ...prevFilter,
      review: !prevFilter.review,
    }));
  };

  // Handler to toggle 'callNow'
  const handleCallNowToggle = () => {
    setfilter((prevFilter) => ({
      ...prevFilter,
      callNow: !prevFilter.callNow,
    }));
  };
  const { isMobile } = useGeneralBrain();
  return (
    <div className="w-full h-full bg overflow-auto">
      <div className="  p-5  text-sm py-3 ">
        <div className="flex justify-between border-bottom-brown items-center ">
          <div className="flex p-2 py-3 text-sm  hover">
            <Filter1Icon />
            <div>Filtrar</div>
          </div>
          <div className="flex justify-between items-center">
            <div
              className="hover btnbtn rounded-md text-center text-sm font-medium py-1 px-3 bg theme"
              onClick={() => setshowfilter(false)}
            >
              Close
            </div>
            <div className="hover btnbtn rounded-md text-center text-sm font-medium py-1 px-3 tcw themecom">
              {isMobile  ? 'Resultados' : 'Mostrar Resultados (0)'}
            </div>
          </div>
        </div>
        <div className="border-bottom-brown py-5  grid grid-cols-1 sm:grid-cols-2 gap-x-2">
          <div className="mr-5">
            <Sec1
              title={"Valor"}
              t1={`R$ ${filter.value.start}`}
              t2={`até + R$ ${filter.value.end}`}
              s1={200}
              s2={999}
              value={[filter.value.start, filter.value.end]}
              onChange={handleValueChange}
            />
            <Sec1
              title={"Idade"}
              t1={`${filter.age.start} anos`}
              t2={`+ ${filter.age.end} anos`}
              s1={18}
              s2={60}
              value={[filter.age.start, filter.age.end]}
              onChange={handleAgeChange}
            />
            <Sec2
              title={"Reviews"}
              desc={"Com review"}
              value={filter.review}
              setValue={handleReviewToggle}
            />
            <Sec2
              title={"Expediente"}
              desc={"Em expediente agora"}
              value={filter.callNow}
              setValue={handleCallNowToggle}
            />
            <Sec3
              placehoder={"Serviços"}
              options={filter["Serviços"]}
              handleSelect={handleSelect}
              filter={filter}
              setfilter={setfilter}
            />
            <To8sa
              bno={true}
              placehoder={"Método de pagamento"}
              value={filter["Método de pagamento"]}
              dropdowns={dropdowns}
              dropdownId={24}
              toggleDropdown={toggleDropdown}
              options={filter["Método de pagamento"]}
              handleSelect={handleSelect}
              filter={filter}
            />
            <To8sa
              bno={true}
              placehoder={"Local"}
              value={filter["Local"]}
              dropdowns={dropdowns}
              dropdownId={26}
              toggleDropdown={toggleDropdown}
              options={filter["Local"]}
              handleSelect={handleSelect}
              filter={filter}
            />
          </div>
          <div>
            <Sec4
              shift={2}
              title={"Características físicas"}
              dropdowns={dropdowns}
              toggleDropdown={toggleDropdown}
              isButtons={isButtons}
              isRangeInput={isRangeInput}
              isRangeInputChange={isRangeInputChange}
              filter={filter}
              setfilter={setfilter}
            />
            <div className="mt-5">
              <Sec4
                shift={12}
                title={"Outros"}
                dropdowns={dropdowns}
                toggleDropdown={toggleDropdown}
                isButtons={isButtons1}
                filter={filter}
                setfilter={setfilter}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowFilter;
