import React, { useEffect, useState } from "react";
import { useGeneralBrain } from "./Brain";
import ProfileCard from "../components/cards/ProfileCard";
import Slider from "./Slider";
import To14 from "../components/cards/To14";
import { useFetchData } from "./Hooks";
import { calculateAge } from "./Functions";

const Recommend = ({ who, sober }) => {
  const { isMobile } = useGeneralBrain();
  const [myArrays, setMyData] = useState([]);
  const docId = "sao-paulo-sp-escort-womens";

  const { data, loading, error } = useFetchData({
    cols: "lives",
    docId: docId,
  });

  useEffect(() => {
    const filteredPosts = data ? Object.values(data) : [];
    setMyData(filteredPosts);
  }, [data]);

  const MySvg = ({ data }) => {
    return (
      <>
        <div className="flex justify-start items-center text-start">
          <To14
            text={sober ? "Recomendações para" : "Recomendações"}
            sober={sober}
            tc={"tc"}
            size={"sm"}
          />
        </div>
        <div className="grid grid-cols-3 gap-x-5 my-5">
          {data.map((d) => (
            <div key={d.id}>
              <ProfileCard
                who={d.d0}
                imgAds={d.d1}
                imgs={d.d2}
                vids={d.d3}
                plan={d.d4}
                name={d.d5}
                price={d.d6}
                rating={d.d7}
                age={calculateAge(d.d8)}
                city={d.d9}
                ownplace={d.d10}
                sex={d.d11}
                username={d.d12}
                followers={d.d13}
                lastOnline={d.d14}
                iallow={d.d15}
              />
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <>
      {!loading && !error && myArrays.length > 0 && (
        <div className="">
          {!isMobile && (
            <Slider
              who={who}
              data={myArrays}
              svg={MySvg}
              length={3}
              className={" "}
            />
          )}
          {isMobile && (
            <>
              <To14
                text={sober ? "Recomendações para" : "Recomendações"}
                sober={sober}
                tc={"tc"}
                size={"sm"}
              />
              <div
                className={`my-5 sm:my-10 flex items-center overflow-x-scroll`}
              >
                {myArrays.map((d) => (
                  <div key={d.id} className="mr-5">
                    <ProfileCard
                      who={d.d0}
                      imgAds={d.d1}
                      imgs={d.d2}
                      vids={d.d3}
                      plan={d.d4}
                      name={d.d5}
                      price={d.d6}
                      rating={d.d7}
                      age={calculateAge(d.d8)}
                      city={d.d9}
                      ownplace={d.d10}
                      sex={d.d11}
                      username={d.d12}
                      followers={d.d13}
                      lastOnline={d.d14}
                      iallow={d.d15}
                      sm={true}
                      md={true}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Recommend;
