import React from 'react'
import ToggleCard from '../cards/ToggleCard'

const Sec2 = ({title, desc, value, setValue}) => {
  return (
    <div className='my-3'>
        <div className='text-sm font-normal'>{title}</div>
        <div className='flex justify-between items-center'>
            <div className='text-xs btc '>{desc}</div>
            <ToggleCard  value={value} setValue={setValue}/>
        </div>
    </div>
  )
}

export default Sec2