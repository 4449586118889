import { format, formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import lofo from "../assets/logos/Logotipo_peppermodel_SemFundo-04.png";


export function getFileType(url) {
  const extension = url.split("?")[0].split(".").pop().toLowerCase();

  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
  const videoExtensions = ["mp4", "mov", "wmv", "avi", "flv", "mkv", "webm"];

  if (imageExtensions.includes(extension)) {
    return "image";
  } else if (videoExtensions.includes(extension)) {
    return "video";
  } else {
    return "unknown";
  }
}

export const formatDate = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0"); // Ensure 2 digits
  const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
};

export const getObjectLength = (obj) => {
  if (obj) {
    return Object.entries(obj).length;
  }
  return 0;
};

export const generateCode4 = () => {
  const randomChars = "0123456789";
  let randomPart = "";

  for (let i = 0; i < 4; i++) {
    randomPart += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }

  return randomPart; // Convert the string to a number
};

export const generateUid6 = () => {
  const timestamp = new Date().getTime().toString(36);
  const randomString = Math.random().toString(36).substr(2, 4); // Generating a random string of 4 characters
  const randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomPart = "";
  for (let i = 0; i < 2; i++) {
    // Looping twice to get 2 more characters
    randomPart += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return `${timestamp}-${randomString}-${randomPart}`;
};

export const generateUid8 = () => {
  const timestamp = new Date().getTime().toString(36);
  const randomString1 = Math.random().toString(36).substr(2, 3); // Generating a random string of 3 characters
  const randomString2 = Math.random().toString(36).substr(2, 3); // Generating another random string of 3 characters
  const randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomPart = "";
  for (let i = 0; i < 2; i++) {
    // Looping twice to get 2 more characters
    randomPart += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return `${timestamp}-${randomString1}-${randomString2}-${randomPart}`;
};

export const generateUid16 = () => {
  const timestamp = new Date().getTime().toString(36);
  const randomString1 = Math.random().toString(36).substr(2, 6); // Random string, first part
  const randomString2 = Math.random().toString(36).substr(2, 6); // Random string, second part
  const randomString3 = Math.random().toString(36).substr(2, 4); // Random string, third part
  const randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomPart = "";
  for (let i = 0; i < 4; i++) {
    randomPart += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return `${timestamp}-${randomString1}-${randomString2}-${randomString3}-${randomPart}`;
};

export const formatName = (fullName) => {
  const nameParts = fullName.split(" ");

  if (nameParts.length > 1) {
    const firstName = nameParts[0]; // First name
    const lastNameInitial = nameParts[nameParts.length - 1][0]; // First letter of the last name
    return `${firstName} ${lastNameInitial}`;
  } else {
    return fullName; // In case there's only one part (just the first name)
  }
};
export function getCityFromUrl() {
  const path = window.location.pathname; // Get the current URL path
  const pattern = /^(\/posts-|\/acompanhantes-)([a-z-]+)-[a-z]{2}$/; // Regex pattern to match the required URL

  const match = path.match(pattern);
  if (match && match[2]) {
    const cityWithHyphens = match[2]; // Extract the city part (e.g., 'sao-paulo')
    const city = cityWithHyphens.replace(/-/g, " "); // Replace hyphens with spaces
    return city;
  } else {
    return null; // Return null if no match is found
  }
}

export const toLocal = (localName) => {
  // Map of special characters to their normalized counterparts
  const charMap = {
    ã: "a",
    õ: "o",
    ç: "c",
    à: "a",
    á: "a",
    â: "a",
    è: "e",
    é: "e",
    ê: "e",
    ì: "i",
    í: "i",
    î: "i",
    ò: "o",
    ó: "o",
    ô: "o",
    ù: "u",
    ú: "u",
    û: "u",
    ü: "u",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    Ç: "C",
    Â: "A",
    Ê: "E",
    Ô: "O",
    Û: "U",
  };

  // Replace special characters using the charMap
  return localName
    .split("")
    .map((char) => charMap[char] || char)
    .join("")
    .toLowerCase();
};

export const getSex = (value) => {
  let mysex;
  if (value === "male") {
    mysex = "escort-mens";
  } else if (value === "female") {
    mysex = "escort-womens";
  } else {
    mysex = "escort-trans";
  }
  return mysex;
};

export const toSlug = (text) => {
  return (
    text
      .toLowerCase() // Convert to lowercase
      .trim() // Trim leading/trailing spaces
      // .replace(/[^\w\s-]/g, "") // Remove special characters
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/-+/g, "-")
  ); // Replace multiple hyphens with a single hyphen
};

export const generateSlugId = (slug) => {
  return slug.length <= 38 ? slug : slug.slice(0, 38);
};

export const calculateAge = (date) => {
  // Parse the date from the string (format: "YYYY-MM-DD")
  const birthDate = new Date(date);
  const today = new Date();

  // Calculate the difference in years
  let age = today.getFullYear() - birthDate.getFullYear();

  // Adjust if the current date is before the birth date this year
  const monthDifference = today.getMonth() - birthDate.getMonth();
  const dayDifference = today.getDate() - birthDate.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--; // Decrease age if birthday hasn't occurred yet this year
  }

  return age;
};

export const getMyOwn = (gender) => {
  return `${gender === "female" ? "Apenas homens" : ""} 
  ${gender === "male" ? "Apenas mulheres" : ""} 
  ${gender === "trans" ? "Apenas trans" : ""}`;
};

export const TimeAgo = (time) => {
  const date = time.toDate();
  const timeAgo = formatDistanceToNow(date, { addSuffix: true, locale: ptBR });
  const formattedTime = timeAgo.replace("about", "");
  return formattedTime;
};

export const getLastWord = (str) => {
  if (!str) return "Ordenar";
  const words = str.trim().split(/\s+/);
  return words[words.length - 1];
};

export const TimeTheme = (time) => {
  if (time) {
    const date = time.toDate();
    return format(date, "MMMM dd, yyyy", { locale: ptBR });
  }
  return null;
};

export const formatOptions = ({ cities, sign }) => {
  return cities.map((city) => ({
    name: city,
    value: city,
    info: city.substring(0, 3).toUpperCase(),
    url: toLocal(
      `/acompanhantes-${city
        .toLowerCase()
        .replace(/\s+/g, "-")}-${sign.toLowerCase()}`
    ), // Create slug URL
  }));
};

export const formatTags = ({ cities, sign }) => {
  return cities.map((city) => ({
    name: city,
    url: toLocal(
      `/acompanhantes-${city
        .toLowerCase()
        .replace(/\s+/g, "-")}-${sign.toLowerCase()}`
    ), // Create slug URL for each tag
  }));
};

export const ToProfile = (name) => {
  const go = `/u/${name}`;
  return go;
};

export const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
  ],
};

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

// Watermarking utility function
export const addWatermarkToImage = async (imageFile, username = "guest") => {
  return new Promise((resolve, reject) => {
    if (!imageFile) return reject("No image file provided");

    const image = new Image();
    image.src = URL.createObjectURL(imageFile);

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = image.width;
      canvas.height = image.height;

      // Draw the original image
      ctx.drawImage(image, 0, 0);

      // Set the transparency for the watermark text
      const transparency = 0.5; // Adjust this value for desired transparency
      ctx.globalAlpha = transparency;

      // Add watermark at intervals
      const intervalX = 150; // Horizontal space between watermarks
      const intervalY = 150; // Vertical space between watermarks
      const watermarkText = "peppermodel.com";
      const fontSize = 15;

      ctx.font = `${fontSize}px Arial`;
      ctx.textAlign = "center";

      for (let x = intervalX / 2; x < image.width; x += intervalX) {
        for (let y = intervalY; y < image.height; y += intervalY) {
          const isWhite = (y / intervalY) % 2 === 0;
          ctx.fillStyle = isWhite ? "white" : "black";

          // Draw domain name
          ctx.fillText(watermarkText, x, y);
          // Draw username just below domain
          ctx.fillText(`u/${username}`, x, y + fontSize);
        }
      }

      // Reset globalAlpha to fully opaque before adding the logo
      ctx.globalAlpha = 1;

      // Add logo image in the bottom-right corner
      const logo = new Image();
      logo.src = lofo; // Make sure the logo path is correctly provided
      logo.onload = () => {
        const logoWidth = 120;
        const logoHeight = 30;
        ctx.drawImage(
          logo,
          image.width - logoWidth - 30,
          30,
          logoWidth,
          logoHeight
        );

        // Resolve with the watermarked image as a data URL
        const watermarkedImageURL = canvas.toDataURL("image/jpeg");
        resolve(watermarkedImageURL);
      };

      logo.onerror = () => reject("Failed to load logo image");
    };

    image.onerror = () => reject("Failed to load image");
  });
};
