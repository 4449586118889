import React, { useEffect, useState } from "react";
import { SearchIcon } from "../data/Data";
import BarNav from "../components/cards/BarNav";
import { LocationBtn } from "./components";
import Overlay from "../components/general/Overlay";
import Location from "./Location";
import { getCityFromUrl } from "./Functions";
import { useLocation } from "react-router-dom";

const Filter = ({ active, setactive, allData, setMyData, loading, error }) => {
  useEffect(() => {
    if (!loading && !error && allData) {
      const filteredWorks = Object.entries(allData).map(([dataId, data]) => ({
        ...data,
        id: dataId,
        d14: "8 minutes",
      }));
      setMyData(filteredWorks);
    }

    /* eslint-disable-next-line */
  }, [allData, error]);
  const [showOverlay1, setShowOverlay1] = useState(false);

  const location = useLocation();
  useEffect(() => {
   
    setShowOverlay1(false);
  }, [location.pathname]); 
  return (
    <div className="">
      <div className=" gap-5 sm:mt-5 ">
        <div  onClick={() => setShowOverlay1(true)} className="bg btnbtn21 w-full flex gap-2 items-center mt-4 py-1.5 px-2 rounded-md ">
          <SearchIcon size={20} color="#8593A3" />
          <input
            type="text"
            placeholder="Busque pela sua cidade ou serviço que procura"
            className="outline-none tc bg section2-width pl-1 text-xs"
          />
        </div>
      </div>
      <div className=" md:flex md:justify-between items-center">
        <BarNav
          value={active}
          setValue={setactive}
          t1={"Mulheres"}
          v1={"escort-womens"}
          t2={"Homens"}
          v2={"escort-mens"}
          t3={"Trans"}
          v3={"escort-trans"}
        />
        <div onClick={() => setShowOverlay1(true)} className=" flex justify-center items-center ">
          <LocationBtn text={getCityFromUrl()} />
        </div>
      </div>
      {showOverlay1 && <Overlay card={Location} setshowfilter={setShowOverlay1} />} 
    </div>
  );
};

export default Filter;
