import React from "react";
import { formatName } from "../../controller/Functions";
import ImageCard from "./ImageCard";

const To5b = ({ img, name }) => {
  return (
    <div className="bg-boxer hover:scale-105 cursor-pointer transition-all ease-in-out tcw status-width-box-1 flex justify-center items-center">
      <div className="flex flex-col gap-3 text-center cursor-pointer">
        <div className="t05-con">
          <div className="bglb rounded-full">
            <ImageCard
              src={img}
              alt="profile img"
              className="t05-con-img bglb"
            />
          </div>
        </div>
        <div className="text-sm capitalize my-0.5">{formatName(name)}</div>
      </div>
    </div>
  );
};

export default To5b;
