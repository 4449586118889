import React, { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import dark from "../../assets/dark.png";
import white from "../../assets/white.png";
const NothingYet = ({ text, title }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="allsvgclass my-5 md:my-20">
      <h3>{title ? title : "EMPTY"}</h3>
      <div>
        {theme === "light" ? (
          <img src={dark} alt="Nothing yet" className="svgclass" />
        ) : (
          <img src={white} alt="Nothing yet" className="svgclass" />
        )}
      </div>
      <h5>{text || "Nothing here"}</h5>
    </div>
  );
};

export default NothingYet;
