import React, { useState, useEffect } from "react";
import StatusCard from "../components/cards/StatusCard";
import To5b from "../components/cards/To5b";
import { ChevLeftIcon, ChevRightIcon } from "../data/Data";
import { motion } from "framer-motion";

const Status = ({ id, data }) => {
  const aStatusTime = 5000; // 5 seconds for each status
  const [currentIndex, setCurrentIndex] = useState(
    data.findIndex((item) => item.id === id)
  );
  const [statusIndex, setStatusIndex] = useState(0);  // Index to track statuses within a user
  const [direction, setDirection] = useState(0);  // Track direction of change (left or right)

  // Get current user data and their statuses
  const currentStatus = data[currentIndex];
  const statusKeys = Object.keys(currentStatus.status); // Array of status keys (e.g., ["status-6-1", "status-6-2"])
  const statusValues = Object.values(currentStatus.status); // Array of status values (e.g., [{url, type, likes...}, ...])
  const totalStatuses = statusKeys.length;

  const myStatus = {
    ...statusValues[statusIndex], // Current status data (e.g., likes, views, type)
    userFullName: currentStatus.userFullName,
    pfpImg: currentStatus.pfpImg,
    username: currentStatus.username,
    statusId: statusKeys[statusIndex], // Status ID (e.g., "status-6-1")
  };

  // Automatically cycle through statuses within the current user
  useEffect(() => {
    const interval = setInterval(() => {
      setStatusIndex((prevIndex) => {
        if (prevIndex + 1 >= totalStatuses) {
          setCurrentIndex((prevUserIndex) =>
            prevUserIndex + 1 >= data.length ? 0 : prevUserIndex + 1
          );
          return 0;
        }
        return prevIndex + 1;
      });
    }, aStatusTime);

    return () => clearInterval(interval); 
  }, [statusIndex, currentIndex, data, totalStatuses]);

  // Handle left and right click for user navigation
  const handleLeftClick = () => {
    setDirection(-1);  // Set direction to left
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
    setStatusIndex(0);
  };

  const handleRightClick = () => {
    setDirection(1);  // Set direction to right
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 >= data.length ? 0 : prevIndex + 1
    );
    setStatusIndex(0);
  };

  const prevIndex = currentIndex === 0 ? data.length - 1 : currentIndex - 1;
  const nextIndex = currentIndex === data.length - 1 ? 0 : currentIndex + 1;
  const prevStatus = data[prevIndex];
  const nextStatus = data[nextIndex];

// Motion variants for right-to-left animation by default
const variants = {
  enter: (direction) => ({
    x: direction > 0 ? -300 : 300, // Change this to start from the left (-300) by default
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
    transition: {
      x: { type: "spring", stiffness: 300, damping: 30 },
      opacity: { duration: 0.2 }
    }
  },
  exit: (direction) => ({
    x: direction < 0 ? -300 : 300, // Change exit to match the opposite of the enter animation
    opacity: 0,
    transition: {
      x: { type: "spring", stiffness: 300, damping: 30 },
      opacity: { duration: 0.2 }
    }
  })
};

  return (
    <div className="h-dvh tc bgbt overflow-hidden lg:flex lg:justify-center lg:items-center">
      <div className="lg:hidden w-full h-full">
        <motion.div
          key={statusIndex}
          variants={variants}
          custom={direction}
          initial="enter"
          animate="center"
          exit="exit"
          className="w-full h-full"
        >
          <StatusCard
            data={myStatus}
            counts={totalStatuses}
            countIndex={statusIndex}
            aStatusTime={aStatusTime}
          />
        </motion.div>
      </div>

      <div className="hidden lg:flex lg:justify-between lg:items-center">
        <motion.div
          key={prevIndex}
          variants={variants}
          custom={direction}
          initial="enter"
          animate="center"
          exit="exit"
        >
          <To5b img={prevStatus?.pfpImg} name={prevStatus?.userFullName} />
        </motion.div>

        <div className="flex items-center">
          <p
            onClick={handleLeftClick}
            className="mx-5 bg p-2 rounded-full hover"
          >
            <ChevLeftIcon />
          </p>

          <motion.div
            key={currentIndex}
            variants={variants}
            custom={direction}
            initial="enter"
            animate="center"
            exit="exit"
            className="status-width-box"
          >
            <StatusCard
              data={myStatus}
              counts={totalStatuses}
              countIndex={statusIndex}
              aStatusTime={aStatusTime}
            />
          </motion.div>

          <p
            onClick={handleRightClick}
            className="mx-5 bg p-2 rounded-full hover"
          >
            <ChevRightIcon />
          </p>
        </div>

        <motion.div
          key={nextIndex}
          variants={variants}
          custom={direction}
          initial="enter"
          animate="center"
          exit="exit"
        >
          <To5b img={nextStatus?.pfpImg} name={nextStatus?.userFullName} />
        </motion.div>
      </div>
    </div>
  );
};

export default Status;
