import React, { useState } from "react";
import { SelectBtn } from "../../controller/components";
import To27 from "../cards/To27";

const Sec3 = ({ options, filter, handleSelect, placehoder }) => {

  const [show, setshow] = useState(20)
  return (
    <div className="mb-3">
      <div className="text-sm font-normal my-2">{placehoder}</div>
      <div className="flex flex-wrap gap-1">
        {options && (
          <>
            {Object.keys(options)
              .slice(0, show)
              .map((optionKey, index) => (
                <SelectBtn
                  key={index}
                  text={optionKey}
                  selected={filter?.[placehoder][optionKey]}
                  onClick={() => handleSelect(optionKey, placehoder)}
                />
              ))}
          </>
        )}

       { show < 48 &&  <To27 text={'Ver mais'} onClick={() => setshow(show + 5 )}/>}
      </div>
    </div>
  );
};

export default Sec3;
