// info.js

export const projectName = "peppermodel"; 

export const domain = window.location.origin;
export const isAdmin = window.location.pathname.includes("admin-panel");
export const level = "level 1";
export const levelNum = 1;
export const name = "focus";
export const email = "focus@gmail.com";

export const verificationStatus = "";

export const planInfo = (plan) => {
  let btnbtn;
  let planText;
  let planTextbc;
  let planTexttc;
  let planTextbr;
  let bgClass;
  let nameClass;
  let textClass;
  let lineClass;
  let iconClass;
  let iconsClass;

  if (plan === "free") {
    btnbtn = "btnbtn21";
    planText = "";
    nameClass = "tc";
    textClass = "tc";
    lineClass = "border-bottom-brown";
    iconClass = "var(--themeColor)";
    bgClass = "bg";
    iconsClass = "var(--textColor)";
  } else if (plan === "starter") {
    btnbtn = "btnbtn21";
    planText = "starter";
    planTextbc = "bgt";
    planTexttc = "tcw";
    planTextbr = "btnbtn3";
    nameClass = "tc";
    textClass = "tc";
    lineClass = "border-bottom-brown";
    iconClass = "var(--themeColor)";
    bgClass = "bg";
    iconsClass = "var(--textColor)";
  } else if (plan === "pro") {
    btnbtn = "btnbtn";
    planText = "pro";
    planTextbc = "themecom";
    planTexttc = "tcw";
    planTextbr = "btnbtn";
    nameClass = "tc ";
    textClass = "tc ";
    lineClass = "border-bottom-brown";
    iconClass = "var(--white)";
    iconClass = "var(--themeColor)";
    bgClass = "bg";
  } else if (plan === "prime") {
    btnbtn = "";
    planText = "black";
    planTextbc = "bgb";
    planTexttc = "tcw";
    planTextbr = "btnbtn4";
    nameClass = "tcw";
    textClass = "tcw";
    lineClass = "border-bottom-white";
    iconClass = "var(--themeColor)";
    bgClass = "bgb";
    iconsClass = "var(--white)";
  } else if (plan === "master") {
    btnbtn = " ";
    planText = "black";
    planTextbc = "bgb";
    planTexttc = "tcw";
    planTextbr = "btnbtn4";
    nameClass = "tcw";
    textClass = "tcw";
    lineClass = "border-bottom-white";
    iconClass = "var(--themeColor)";
    bgClass = "bgb";
    iconsClass = "var(--white)";
  } else if (plan === "flash") {
    btnbtn = " ";
    planText = "black";
    planTextbc = "bgb";
    planTexttc = "tcw";
    planTextbr = "btnbtn4";
    nameClass = "tcw";
    textClass = "tcw";
    lineClass = "border-bottom-white";
    iconClass = "var(--themeColor)";
    bgClass = "bgb";
    iconsClass = "var(--white)";
  } else if (plan === "telephone") {
    btnbtn = "btnbtn";
    planText = "";
    nameClass = "theme";
    textClass = "tc";
    lineClass = "border-bottom-theme";
    iconClass = "var(--themeColor)";
    bgClass = "bg";
    iconsClass = "var(--textColor)";
  }
  const planDetail = {
    btnbtn,
    planText,
    planTextbc,
    planTexttc,
    planTextbr,
    bgClass,
    nameClass,
    textClass,
    lineClass,
    iconClass,
    iconsClass,
  };
  return planDetail;
};
