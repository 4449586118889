import React, { useState, useEffect } from "react";
import { useGeneralBrain } from "../../controller/Brain";
import To9 from "../cards/To9";
import To8 from "../cards/To8";
import ButtonCard from "../cards/ButtonCard";
import Codes from "../Auth/Codes";
import { generateCode4 } from "../../controller/Functions";
import { doc, runTransaction } from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import To8p from "../cards/To8p";
import { isValidPhoneNumber } from "react-phone-number-input";
import { sendEmail, sendSms } from "../../controller/Apis";

const AuthBox = () => {
  const { userData, setShowOverBoard, isMobile, setlogger} = useGeneralBrain();

  const requireEmail = userData?.authe;
  const requireSms = userData?.auths;
  const requireWhatsapp = userData?.authw;

  const todoAdd =
    (requireEmail && !userData?.userEmail) ||
    (requireSms && !userData?.atelephone) ||
    (requireWhatsapp && !userData?.awhatsapp)
      ? false
      : true;

  const [loading, setLoading] = useState(false);
  const [isValidated, setisValidated] = useState(false);
  const [sendCode, setsendCode] = useState(todoAdd);

  const [sms, setSms] = useState(userData?.atelephone || "");
  const [whatsapp, setWhatsapp] = useState(userData?.awhatsapp || "");

  const [emailCode, setEmailCode] = useState("");
  const [smsCode, setSmsCode] = useState("");
  const [whatsappCode, setWhatsappCode] = useState("");

  const [error1, seterror1] = useState("");
  const [error2, seterror2] = useState("");
  const [error3, seterror3] = useState("");

  const handleAuthorize = async () => {
    try {
      await runTransaction(db, async (transaction) => {
        const userDocRef = doc(db, "users", userData?.userId);
        transaction.update(userDocRef, {
          isAuth: false,
          isLogin: true,
          atelephone: sms,
          awhatsapp: whatsapp,
          authcodee: "",
          authcodes: "",
          authcodew: "",
        });
      });
      setShowOverBoard("");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let hasError = false;
    seterror1("");
    seterror2("");
    seterror3("");

    // Check emailCode: only validate if the length is 4
    if (emailCode.length === 4 && requireEmail) {
      if (emailCode !== String(userData?.authcodee)) {
        seterror1("Incorrect code entered");
        hasError = true;
      }
    }

    // Check smsCode: only validate if the length is 4
    if (smsCode.length === 4 && requireSms) {
      if (smsCode !== String(userData?.authcodes)) {
        seterror2("Incorrect code entered");
        hasError = true;
      }
    }

    // Check whatsappCode: only validate if the length is 4
    if (whatsappCode.length === 4 && requireWhatsapp) {
      if (whatsappCode !== String(userData?.authcodew)) {
        seterror3("Incorrect code entered");
        hasError = true;
      }
    }

    if (requireEmail && emailCode.length < 4) {
      hasError = true;
    }

    if (requireSms && smsCode.length < 4) {
      hasError = true;
    }

    if (requireWhatsapp && whatsappCode.length < 4) {
      hasError = true;
    }
    if (!hasError) {
      setLoading(true);
      handleAuthorize();
    } else {
      setLoading(false);
    }

    /* eslint-disable-next-line */
  }, [emailCode, smsCode, whatsappCode, userData]);

  const [codeSent1, setCodeSent1] = useState(false);
  const [timer1, setTimer1] = useState(0);
  const [codeSent2, setCodeSent2] = useState(false);
  const [timer2, setTimer2] = useState(0);
  const [codeSent3, setCodeSent3] = useState(false);
  const [timer3, setTimer3] = useState(0);

  useEffect(() => {
    setisValidated(false);
    // Check if SMS number is required and valid
    const smsValid =
      requireSms && sms && isValidPhoneNumber(sms) && !requireWhatsapp;

    // Check if WhatsApp number is required and valid
    const whatsappValid =
      requireWhatsapp &&
      whatsapp &&
      isValidPhoneNumber(whatsapp) &&
      !requireSms;

    // Set validation state based on the valid phone numbers
    if (smsValid || whatsappValid || (smsValid && whatsappValid)) {
      setisValidated(true);
    }
  }, [sms, whatsapp, requireSms, requireWhatsapp]);

  // Handle sending the code and starting the timer
  const handleSendCodeEmail = async () => {
    if (!userData?.userEmail) return;

    try {
      const code = generateCode4();
      await runTransaction(db, async (transaction) => {
        const userDocRef = doc(db, "users", userData?.userId);
        transaction.update(userDocRef, { authcodee: code });
      });

      const result = await sendEmail({
        from: "PepperModel <auth@peppermodel.com>",
        recipient: userData?.userEmail,
        subject: "Your verification code!",
        message: `<p>Your code is ${code}</p>`,
      });

      if (result.success) {
        setCodeSent1(true);
      }

      setTimer1(300); // 5 minutes countdown (300 seconds)
    } catch (error) {
      console.error(error);
    }
  };

  const handleSendCodeSms = async () => {
    if (!sms) return;
    try {
      const code = generateCode4();
      await runTransaction(db, async (transaction) => {
        const userDocRef = doc(db, "users", userData?.userId);
        transaction.update(userDocRef, { authcodes: code });
      });

      const result = await sendSms({
        from: "+14693511326",
        recipient: sms,
        message: `Your code is ${code}`,
      });

      if (result.success) {
        setCodeSent2(true);
      }

      setTimer2(300); // 5 minutes countdown (300 seconds)
    } catch (error) {
      console.error(error);
    }
  };

  const handleSendCodeWhatapp = async () => {
    if (!whatsapp) return;
    try {
      const code = generateCode4();
      await runTransaction(db, async (transaction) => {
        const userDocRef = doc(db, "users", userData?.userId);
        transaction.update(userDocRef, { authcodew: code });
      });

      //send the code handleRegister

      setCodeSent3(true);
      setTimer3(300); // 5 minutes countdown (300 seconds)
    } catch (error) {
      console.error(error);
    }
  };

  const handleVerify = async () => {
    setsendCode(true);
  };

  const handleCancel = async () => {
    try {
      setShowOverBoard("");
      setlogger(false)
      // await runTransaction(db, async (transaction) => {
      //   const userDocRef = doc(db, "users", userData?.userId);
      //   transaction.update(userDocRef, {});
      // });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={"overflow-auto"}>
      <p className="flex justify-center items-center">
        <div
          onClick={handleCancel}
          className="btnbtn -mb-5 theme hover-bg p-1 text-8 w-max text-center  rounded-md"
        >
          Cancel
        </div>
      </p>

      <div className="c-form-container c-form-container-width transition-all ease-in-out">
        {!sendCode && (
          <>
            {!todoAdd && (
              <div>
                <To9
                  title={"Informações de autenticação"}
                  desc={
                    !isMobile
                      ? "Requer detalhes para autorização adicional."
                      : ""
                  }
                  sm={true}
                />
                {requireEmail && !userData?.userEmail && (
                  <To8
                    placehoder={"Digite seu email*"}
                    value={userData?.userEmail}
                    label={"Email"}
                    type={"email"}
                    disabled={true}
                  />
                )} 

                {requireSms && !userData?.atelephone && (
                  <To8p
                    placehoder={"Enter your phone number*"}
                    label={"SMS Number:"}
                    type={"number"}
                    value={sms}
                    setValue={setSms}
                  />
                )}

                {requireWhatsapp && !userData?.awhatsapp && (
                  <To8p
                    placehoder={"Enter your WhatsApp number*"}
                    label={"WhatsApp Number:"}
                    type={"number"}
                    value={whatsapp}
                    setValue={setWhatsapp}
                  />
                )}

                <ButtonCard
                  text={!loading ? "Proceed" : "loading..."}
                  onClick={!loading ? handleVerify : undefined}
                  className={`themecom tcw py-3 
                       "opacity-65`}
                  disabled={!isValidated || loading}
                />
              </div>
            )}
          </>
        )}

        {sendCode && (
          <div>
            <To9
              title={"Adicional autenticação"}
              desc={
                !isMobile ? "Requer detalhes para autorização adicional." : ""
              }
              sm={true}
            />
            {requireEmail && (
              <Codes
                title={"Email"}
                desc={"Code for Email"}
                onClick={handleSendCodeEmail}
                value={emailCode}
                setValue={setEmailCode}
                timer={timer1}
                setTimer={setTimer1}
                codeSent={codeSent1}
                error={error1}
              />
            )}
            {requireSms && (
              <Codes
                title={"Sms"}
                desc={"Code for Sms"}
                onClick={handleSendCodeSms}
                value={smsCode}
                setValue={setSmsCode}
                timer={timer2}
                setTimer={setTimer2}
                codeSent={codeSent2}
                error={error2}
              />
            )}
            {requireWhatsapp && (
              <Codes
                title={"Whatsapp"}
                desc={"Code for Whatsapp"}
                onClick={handleSendCodeWhatapp}
                value={whatsappCode}
                setValue={setWhatsappCode}
                timer={timer3}
                setTimer={setTimer3}
                codeSent={codeSent3}
                error={error3}
              />
            )}

            <ButtonCard
              text={!loading ? "Proceed" : "loading..."}
              className={`themecom tcw py-3 
                       opacity-65 cursor-not-allowed`}
              disabled={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthBox;
