import React from "react";
import { CaretUp } from "../../data/Data";

const BackTop = () => {
  return (
    <div
      onClick={() => window.scrollTo(0, 0)}
      className="flex justify-center items-center my-5"
    >
      <div className="themecom flex justify-center items-center p-1 w-max rounded-full">
        <CaretUp />
      </div>
    </div>
  );
};

export default BackTop;
