import React, { useState} from "react";
import { ArrowBackIcon, ArrowFrontIcon } from "../data/Data";
import { motion } from "framer-motion";

const Slider = ({
  who,
  theme,
  size,
  svg: Svg,
  className,
  data,
  length,
  isMobile,
  dots,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0); // To track the previous index

  // Handle going forward
  const handleNext = () => {
    if (currentIndex < data.length - length) {
      setPrevIndex(currentIndex);
      setCurrentIndex(currentIndex + length);
    }
  };

  // Handle going backward
  const handlePrev = () => {
    if (currentIndex > 0) {
      setPrevIndex(currentIndex);
      setCurrentIndex(currentIndex - length);
    }
  };

  // Calculate the total number of dots based on the total data length and the visible length
  const totalDots = Math.ceil(data.length / length);

  // Define the animation direction
  const animationDirection = currentIndex > prevIndex ? 100 : -100;

  return (
    <div className="">
      <div className="flex justify-between items-center sm:-mx-8">
        <div onClick={handlePrev}>
          <IconSlide
            theme={theme}
            size={size}
            svg={ArrowBackIcon}
            className={""}
          />
        </div>

        <motion.div
          className={`${className}`}
          key={currentIndex} // Ensure motion detects a change
          initial={{ x: animationDirection, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -animationDirection, opacity: 0 }}
          transition={{ type: "tween", duration: 0.5 }}
        >
          {Svg && (
            <Svg
              who={who}
              data={data.slice(currentIndex, currentIndex + length)}
              isMobile={isMobile ? isMobile : ""}
            />
          )}
        </motion.div>

        <div onClick={handleNext}>
          <IconSlide
            theme={theme}
            size={size}
            svg={ArrowFrontIcon}
            className={""}
          />
        </div>
      </div>

      {/* Dots navigation */}
      {dots !== false && (
        <div className="flex justify-center items-center my-5 sm:my-8 ">
          {[...Array(totalDots)].map((_, index) => (
            <div
              key={index}
              className={`w-1 h-1 rounded-full mr-1 ${
                theme ? "bg" : "themecom"
              } ${currentIndex / length === index ? "w-2 h-2" : ""}`}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

// Helper component for arrow icons
const IconSlide = ({ svg: Svg, size, theme, className }) => {
  return (
    <div
      className={`h-6 w-6 p-1.5 cursor-pointer ${className} ${
        theme ? "bg" : "themecom"
      } rounded-full flex justify-center items-center`}
    >
      <Svg
        size={size || 15}
        color={`${theme ? "var(--themeColor)" : "var(--bg)"}`}
      />
    </div>
  );
};

export default Slider;
