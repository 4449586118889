import React, { useEffect, useState } from "react";
import { Cancel1Icon, LoveIcon } from "../../data/Data";
import { Love1Icon, PeepIcon, Share1Icon } from "../../data/Data";
import { Link, useNavigate } from "react-router-dom";
import { useGeneralBrain } from "../../controller/Brain";
import { TimeAgo, ToProfile } from "../../controller/Functions";
import ImageCard from "./ImageCard";
import { FacebookIcon, TwitterIcon, WhatsappIcon } from "../../data/Data";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  arrayRemove,
  arrayUnion,
  doc,
  increment,
  runTransaction,
} from "firebase/firestore";
import { db } from "../../utils/init-firebase";

const StatusCard = ({ data, counts, countIndex, aStatusTime }) => {
  const { isMobile, setShowFullPage } = useGeneralBrain();
  const [progressBars, setProgressBars] = useState([]);
  const [show, setshow] = useState(false);

  const {
    userData,
    statusList,
    setStatusList,
    setStatusData,
    statusDocId: docId,
  } = useGeneralBrain();

  useEffect(() => {
    // Update the progress bar fill based on countIndex
    const bars = Array(counts)
      .fill(0)
      .map((_, index) => {
        if (index < countIndex) {
          return 100; // Fully filled for past statuses
        } else if (index === countIndex) {
          return 0; // Will fill progressively for current status
        } else {
          return 0; // Empty for future statuses
        }
      });

    setProgressBars(bars);

    // Only start filling progress for the current status if it's not the last one
    if (countIndex < counts) {
      const interval = setInterval(() => {
        setProgressBars((prevBars) => {
          const updatedBars = [...prevBars];
          updatedBars[countIndex] = Math.min(updatedBars[countIndex] + 10, 100); // Increase fill by 10% every 100ms
          return updatedBars;
        });
      }, aStatusTime / 10); // Smoothly fill over the time period

      return () => clearInterval(interval); // Clear interval on unmount or change
    }
  }, [countIndex, counts, aStatusTime, data]);

  const IconText = ({ svg: Svg, text, onClick, tsvg }) => {
    return (
      <div
        onClick={onClick}
        className="flex items-center flex-col cursor-pointer hover"
      >
        <Svg
          size={isMobile ? 30 : 25}
          color={tsvg ? "var(--themeColor)" : "#fff"}
        />
        <div className="text-sm font-medium">{text}</div>
      </div>
    );
  };

  const navigate = useNavigate();

  const isLiked = statusList.includes(data.statusId);
  const shareUrl = `${window.location.href}/post/${data.statusId}`;

  const handleLike = async () => {
    if (!userData) {
      navigate("/login");
      setShowFullPage(false);
      return;
    }

    if (userData) {
      return;
    }

    await runTransaction(db, async (transaction) => {
      setStatusData((prevData) =>
        prevData.map((post) => {
          if (post.statusId === data.statusId) {
            return { ...post, likes: post.likes + (isLiked ? -1 : 1) };
          }
          return post;
        })
      );

      const listRef = doc(db, "lists", userData?.userId);
      if (isLiked) {
        // Remove the id if it's already liked
        transaction.update(listRef, {
          status: arrayRemove(data.statusId),
        });
        setStatusList((prevList) =>
          prevList.filter((id) => id !== data.statusId)
        );
      } else {
        setStatusList((prevList) => [...prevList, data.statusId]);
        // Add the id if it's not liked
        transaction.update(listRef, {
          posts: arrayUnion(data.statusId),
        });
      }

      const liveRef = doc(db, "lives", docId);

      transaction.update(liveRef, {
        [`${userData?.userId}.${"status"}.${data.statusId}.${"likes"}`]:
          increment(isLiked ? -1 : 1),
      });
    });
  };

  const Comp = ({ btn: Btn, text, svg: Svg }) => {
    return (
      <div className="flex justify-center bgb items-center py-2 hover-bb">
        <Btn url={shareUrl}>
          <div className="flex justify-center items-center">
            <Svg color={"#fff"} />
            <div>{text}</div>
          </div>
        </Btn>
      </div>
    );
  };

  return (
    <div className="w-full h-full bg-black tcw">
      <div className="height-10 w-full px-5 py-1">
        <div className="flex space-x-1 h-1/6 w-full ">
          {progressBars.slice(0, counts).map((progress, index) => (
            <div
              key={index}
              className="bg-gray-400 rounded h-0.5 flex-1 overflow-hidden"
            >
              <div
                className="bg-white h-full"
                style={{
                  width: `${progress}%`,
                }}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-between items-center  w-full h-5/6">
          <div className="flex justify-between items-center tcw ">
            <ImageCard
              src={data.pfpImg}
              alt=""
              className="h-12 w-12 rounded-full object-cover"
            />
            <div className="text-sm font-medium">{data.userFullName}</div>
            <div className="text-xs font-normal">{TimeAgo(data.time)}</div>
          </div>
          <div className="hover" onClick={() => setShowFullPage(false)}>
            <Cancel1Icon color="#fff" />
          </div>
        </div>
      </div>
      <>
        {data.type === "image" && (
          <ImageCard
            src={data.url}
            alt=""
            className="w-dvw sm:w-full  height-80 object-cover bglb"
          />
        )}
        {data.type === "video" && (
          <video
            controls
            src={data.url}
            autoPlay // Automatically plays the video
            muted
            loop
            alt=""
            className="w-dvw sm:w-full  height-80 object-cover bglb"
          />
        )}
      </>

      <div className="flex justify-between items-center p-5 w-full height-10">
        <div onClick={handleLike} className="flex justify-between items-center">
          <IconText
            svg={isLiked ? LoveIcon : Love1Icon}
            tsvg={isLiked}
            text={"Curtir"}
          />
        </div>
        <div onClick={() => setshow(show ? false : true)} className="relative">
          <IconText svg={Share1Icon} text={"Compartilhar"} />
          {show && (
            <div className="overflow-hidden box-shadow bg text-xs w-40 sm:w-52 rounded-lg bottom-5 absolute border-top-brown border-bottom-brown my-1">
              <Comp
                btn={FacebookShareButton}
                svg={FacebookIcon}
                text={"Facebook"}
              />
              <div className="border-bottom-brown border-top-brown">
                <Comp
                  btn={WhatsappShareButton}
                  svg={WhatsappIcon}
                  text={"Whatsapp"}
                />
              </div>
              <Comp
                btn={TwitterShareButton}
                svg={TwitterIcon}
                text={"Twitter"}
              />
            </div>
          )}
        </div>
        <Link to={`${ToProfile(data.username)}`}>
          {" "}
          <IconText svg={PeepIcon} text={"Ver perfil"} />
        </Link>
      </div>
    </div>
  );
};

export default StatusCard;
