import React from "react";
import To8sa from "../cards/To8sa";

const Sec4 = ({
  title,
  dropdowns,
  toggleDropdown,
  shift,
  isButtons,
  isRangeInput,
  isRangeInputChange,
  filter,
  setfilter,
}) => {
  const handleSelect = (value, name) => {
    setfilter((prevFilter) => ({
      ...prevFilter,
      [name]: {
        ...prevFilter[name],
        [value]: !prevFilter[name][value], // Toggle the checkbox value
      },
    }));
  };

  return (
    <div>
      <div className="tc my-2 text-xs font-normal opacity-30">{title}</div>
      <div className="ml-3">
        {isRangeInput?.map((item, index) => (
          <To8sa
            key={index}
            bno={true}
            placehoder={item}
            value={filter[item]}
            dropdowns={dropdowns}
            dropdownId={index + shift}
            toggleDropdown={toggleDropdown}
            options={filter[item]}
            handleSelect={handleSelect}
            isRangeInput={true}
            filter={filter}
            handleValueChange={isRangeInputChange[index]}
            shift={2}
          />
        ))}
        {isButtons.map((item, index) => (
          <To8sa
            key={index}
            bno={true}
            placehoder={item}
            value={filter[item]}
            dropdowns={dropdowns}
            dropdownId={index + shift + 3}
            toggleDropdown={toggleDropdown}
            options={filter[item]}
            handleSelect={handleSelect}
            filter={filter}
          
          />
        ))}
      </div>
    </div>
  );
};

export default Sec4;
