import React from "react";
import { SortIcon } from "../../data/Data";
import { useNavigate } from "react-router-dom";

const ShowOrder = ({ value, setValue, setshoworder }) => {
  const sorts = [
    "Menor valor",
    "Maior valor",
    "Último Online",
    "Último Offline",
    "Reseñas más valoradas",
    "Reseñas melhor valoradas",
    "jovem Idade",
    "avançada idade ",
  ];

  const navigate = useNavigate(); // For navigation and updating the URL

  const handleClick = (sortOption) => {
    setValue(sortOption); // Update the selected value in state
    setshoworder(false); // Hide the order dropdown/modal
    // Get the current URL without query parameters
    const currentPath = window.location.pathname;

    // Format the sortOption to remove spaces and special characters for URL
    const formattedSort = sortOption.replace(/\s+/g, "-").toLowerCase();

    // Navigate to the new URL with the sort query parameter, keeping the current path
    navigate(`${currentPath}?sort=${formattedSort}`);
  };

  return (
    <div className="m-5 py-3">
      <div className="flex p-2 text-sm hover">
        <SortIcon />
        <div>Ordenar</div>
      </div>
      <div className="border-top-brown border-bottom-brown py-5 grid grid-cols-2 gap-x-2">
        {sorts.map((sortOption, index) => (
          <div
            key={index}
            onClick={() => handleClick(sortOption)}
            className={`flex items-center cursor-pointer space-x-2 text-xs font-normal p-1 rounded-md capitalize  ${value === sortOption ? "theme" : "hover-bg"}`}
          >
            {/* Checkbox icon */}
            <div
              className={`w-4 h-4 rounded-sm flex justify-center items-center ${
                value === sortOption ? "btnbtn" : "btnbtn21"
              }`}
            >
              {value === sortOption && (
                <span className="w-2 h-2 rounded-sm themecom"></span>
              )}
            </div>
            {/* Option Text */}
            <div className=''>
              {sortOption}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShowOrder;
