import React from "react";
import AuthBox from "./AuthBox";

const OverBoard = ({ show }) => {
    
  return (
    <>
      {show && (
        <div>
          <div className="cookie-overlay "></div>
          <div className="cookie-consent bg">
            {show === "auth" && <AuthBox />}
          </div>
        </div>
      )}
    </>
  );
};

export default OverBoard;
