import React from "react";

const ButtonCard = ({
  className,
  type,
  text,
  uppercase,
  capitalize,
  theme,
  width,
  loading,
  onClick,
  disabled // new prop for controlling button state
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled} // Disable the button if required
      style={{
        cursor: loading || disabled ? "not-allowed" : "pointer", // Prevent click when loading or disabled
        opacity: disabled ? 0.65 : 1, // Change opacity when disabled
      }}
      className={`${className} hover w-full text-center rounded-md my-5 py-2 text-10 font-bold ${
        theme ? "btnbtn theme" : ""
      } ${width} ${uppercase ? "uppercase" : ""} ${capitalize ? "capitalize" : ""}`}
    >
      {loading ? "Loading..." : text} {/* Show loading state if applicable */}
    </button>
  );
};

export default ButtonCard;
