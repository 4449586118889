import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/Footer";
import Consent from "../components/general/Consent";
import { useGeneralBrain } from "./Brain";
import Navbar from "../components/Navbar";
import { projectName } from "../data/Info";
import { useAuth } from "../contexts/AuthContext";
import PageIsLoading from "../components/general/PageIsLoading";
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "../contexts/ThemeContext";
import { useLocation } from "react-router-dom";
import FullPage from "../components/general/FullPage";
import { loadStripe } from "@stripe/stripe-js";
import OverBoard from "../components/general/OverBoard";

export { loadStripe };

const Container = ({ card: Card, title, path, type, region, query }) => {
  const isAdmin = window.location.pathname.includes("admin-panel");
  const {
    currentUser,
    setwho,
    setDropdowns,
    showOverlay,
    showFullPage,
    setShowFullPage,
    showOverBoard,
  } = useGeneralBrain();
  const { isloading } = useAuth();
  const [open, setOpen] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    document.title = `| ${title} - ${projectName} |`;
    setDropdowns(Array(18).fill(false));
    window.scrollTo(0, 0);
    setwho(path);
    setOpen(false);

    /* eslint-disable-next-line */
  }, [title, path, setwho]);

  useEffect(() => {
    if (!currentUser) {
      window.scrollTo(0, 0);
    }

    /* eslint-disable-next-line */
  }, [currentUser]);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setShowFullPage(false);

    /* eslint-disable-next-line */
  }, [location.pathname]);

  if (isloading) {
    return <PageIsLoading />;
  }

  return (
    <div>
      {isAdmin && (
        <div className="min-h-dvh">
          <Card />
        </div>
      )}

      {!isAdmin && (
        <div
          className={`container-app overflow-x-hidden ${
            showOverlay ? "overflow-hidden" : ""
          }`}
        >
          <div
            className={`general-container overflow-x-hidden ${
              showOverlay ? "overflow-hidden" : ""
            }`}
          >
            {!isAdmin && <Navbar open={open} setOpen={setOpen} />}
            {Card && (
              <Card type={type} title={title} region={region} query={query} />
            )}
          </div>
          <ToastContainer
            position="bottom-right"
            theme={theme === "light" ? "light" : "dark"}
          />
          {<Footer />}
        </div>
      )}
      {showFullPage && (
        <div className="w-dvw h-dvh overflow-hidden">
          <div className="con-overlay"></div>
          <FullPage />
        </div>
      )}

      <OverBoard show={showOverBoard} />
      <Consent />
    </div>
  );
};

export default Container;
