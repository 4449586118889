import React, { useState } from "react";
import To6 from "./To6";
import { Link} from "react-router-dom";
import { Slider } from "infinite-react-carousel";
import {
  BookmarkIcon,
  CalenderIcon,
  GroupIcon,
  HomeIcon,
  KidStar1Icon,
  LocationIcon,
  PaymentIcon,
  VideoSvg,
} from "../../data/Data";
import { planInfo } from "../../data/Info";
import OnlineCard from "./OnlineCard";
import { getMyOwn } from "../../controller/Functions";
import ImageCard from "./ImageCard";

const ProfileCard = ({
  imgAds,
  imgs: images,
  plan,
  name,
  price,
  rating,
  age,
  city,
  ownplace,
  followers,
  sex,
  username,
  md,
  sm,
  who,
}) => {
  const imgs = images ? images : [];

  const planDetail = planInfo(plan);
  const [activeSlide, setActiveSlide] = useState(0);

  const handleDotClick = (index) => {
    setActiveSlide(index);
  };
  const go = `/u/${username}`;

  const myServs = ["Beijo na boca", "Striptease", "Striptease"];
  

  return (
    <Link to={go}>
      <div
       
        className={`relative z-10 ${planDetail?.btnbtn} ${
          md ? "min-w-60" : ""
        } ${
          sm ? "min-w-52 rounded-xl" : "sm:w-64 rounded-3xl"
        } text-xs mb-5  h-max tc m-0`}
      >
        <div
          className={`relative z-10 ${
            sm ? "h-40 rounded-t-xl" : "h-64 sm:h-48 w-full rounded-t-3xl"
          } relative z-10 bglb overflow-hidden`}
        >
          <Slider
            slidesToShow={1}
            arrows={false}
            dots={false}
            className="h-full"
            afterChange={(newIndex) => setActiveSlide(newIndex)} // Update active slide on change
          >
            <ImageCard
              src={imgAds}
              alt=""
              className="w-full h-64 sm:h-48 object-cover"
            />
            {imgs?.length > 0 &&
              imgs?.map((img, index) => (
                <div key={index} className="relative h-full">
                  <ImageCard
                    src={img}
                    alt=""
                    className="w-full h-64 sm:h-48 object-cover"
                  />
                </div>
              ))}
          </Slider>

          {/* Plan Badge */}
          <div className="z-50 absolute top-2 left-2">
            {plan !== "free" && plan !== "telephone" && plan !== "starter" && (
              <span
                className={`${planDetail?.planTextbc} ${planDetail?.planTexttc} ${planDetail?.planTextbr} py-1 px-3 w-14 text-center font-medium text-10 capitalize rounded-2xl`}
              >
                {plan}
              </span>
            )}
          </div>

          {plan !== "free" && plan !== "telephone" && plan !== "starter" && (
            <div
              className={`${
                sm ? "top-36 " : "top-60"
              } z-50 absolute left-1/2 transform -translate-x-1/2 flex space-x-2`}
            >
              {imgs?.map((_, index) => (
                <div
                  key={index}
                  onClick={() => handleDotClick(index)}
                  className={`cursor-pointer rounded-full ${
                    activeSlide === index
                      ? "w-2 h-2 bg-gray-300"
                      : "w-1 h-1 bg-gray-300"
                  }`}
                />
              ))}
            </div>
          )}
        </div>
        <div
          className={`px-5 pt-2 py-5  ${
            sm ? "rounded-b-xl" : "rounded-b-3xl"
          } ${planDetail?.textClass} ${planDetail?.bgClass} overflow-hidden`}
        >
          <div>
            <Link
              to={go}
              className={`text-sm font-semibold capitalize ${planDetail?.nameClass}`}
            >
              {name}
            </Link>
            <div
              className={`text-8 items-center gap-3 py-2 mb-2 ${planDetail?.lineClass}`}
            >
              <OnlineCard />
              {plan !== "free" &&
                plan !== "telephone" &&
                plan !== "starter" && (
                  <div className={`mt-2 text-8 ${planDetail?.nameClass}`}>
                    Breve descrição do perfil e informações interessantes sobre
                    mim.
                  </div>
                )}
            </div>
          </div>
          <div className="flex justify-between items-center my-2 font-bold text-xs">
            <div className="flex items-center gap-3">
              {who !== "content" && (
                <PaymentIcon size={14} color={planDetail?.iconClass} />
              )}
              {who === "content" && <VideoSvg />}
              {who !== "content" && <span className="-ml-1">R$ {price}/h</span>}
              {who === "content" && (
                <span className="-ml-1">+99 conteúdos</span>
              )}
            </div>
            <div className="flex items-center gap-3">
              <KidStar1Icon size={12} color={planDetail?.iconClass} />
              <span className="-ml-1">{rating}</span>
            </div>
          </div>
          {(plan === "prime" || plan === "master") && (
            <div className="mb-2 text-8">
              {myServs.map((service, index) => (
                <span key={index}>
                  {service}
                  {index < myServs.length - 1 && (
                    <span className="w-1 h-1 mx-2 inline-block rounded-full bg-white"></span>
                  )}
                </span>
              ))}
            </div>
          )}
          <div className="grid grid-cols-1 gap-y-1 ">
            <To6
              color={planDetail?.iconsClass}
              cols={planDetail?.textClass}
              size={14}
              sm={true}
              svg={CalenderIcon}
              text={`${age} anos`}
            />
            {who === "escort" && (
              <>
                <To6
                  color={planDetail?.iconsClass}
                  cols={planDetail?.textClass}
                  size={14}
                  svg={LocationIcon}
                  text={`${city}`}
                  sm={true}
                />
              </>
            )}
            {plan !== "free" && plan !== "starter" && (
              <To6
                color={planDetail?.iconsClass}
                cols={planDetail?.textClass}
                svg={BookmarkIcon}
                sm={true}
                text={`${followers} Seguidores`}
                size={14}
              />
            )}
            {who === "escort" && (
              <>
                <To6
                  color={planDetail?.iconsClass}
                  cols={planDetail?.textClass}
                  size={14}
                  svg={HomeIcon}
                  sm={true}
                  text={`${ownplace ? "Com local" : "não com local"}`}
                />
                {plan !== "free" && plan !== "starter" && (
                  <To6
                    color={planDetail?.iconsClass}
                    cols={planDetail?.textClass}
                    size={14}
                    svg={GroupIcon}
                    sm={true}
                    text={getMyOwn(sex)}
                  />
                )}
              </>
            )}
          </div>
          {plan === "telephone" && (
            <Link to={go}>
              <div className="themecom py-2 font-semibold text-xs rounded-md  mt-2 text-center">
                Ver telefone
              </div>
            </Link>
          )}
        </div>
      </div>
    </Link>
  );
};

export default ProfileCard;
