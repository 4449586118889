import React from "react";
import Board from "../../components/cards/Board";
import To8s from "../../components/cards/To8s";
import { regionsOptions } from "../../data/Input";
import RegionCard from "../../components/cards/RegionCard";
import Recommend from "../../controller/Recommend";
import { Brazil, topCities } from "../../data/Brazil";
import { formatOptions, formatTags } from "../../controller/Functions";
import { useGeneralBrain } from "../../controller/Brain";

const Regioes = ({ region }) => {
  const { dropdowns, toggleDropdown } = useGeneralBrain();
  const handleSelect = () => {};

  // Find states in the selected region
  const matchingRegion = Brazil.find((r) => r.region === region);
  const statesInRegion = matchingRegion ? matchingRegion.states : [];

  // Convert cities to objects with name and value

  return (
    <div>
      <Board
        title={"Acompanhantes por regiões do Brasil "}
        subtitle={"Sempre encontre acompanhantes pertinho de você."}
      />
      <div className="mx-5 lg:mx-56 md:mx-20 sm:mx-10 ">
        <div className="-mt-10">
          <div className="text-10 tcw mb-1">Selecione a sua Região</div>
          <To8s
            placehoder={`Região ${region}`}
            value={"rank"}
            dropdowns={dropdowns}
            dropdownId={1}
            toggleDropdown={toggleDropdown}
            options={regionsOptions}
            handleSelect={handleSelect}
          />
        </div>

        <div className="md:grid md:grid-cols-2 md:gap-6 lg:gap-x-10 my-10 sm:mt-14">
          {statesInRegion.map((state, index) => (
            <RegionCard
              key={index}
              title={state.name} // State name
              dropdowns={dropdowns}
              dropdownId={index + 3}
              toggleDropdown={toggleDropdown}
              options={formatOptions({
                cities: state.cities,
                sign: state.sign,
              })} // Cities as options with URL
              handleSelect={handleSelect}
              tags={formatTags({ cities: topCities[state.sign] || [], sign: state.sign })} // Top cities from topCities array
            />
          ))}
        </div>
      </div>
      <div className="bgll py-5">
        <div className="mx-5 sm:mx-10 md:mx-20 lg:mx-56">
          <Recommend who={"escort"} sober={`região ${region}`} />
        </div>
      </div>
    </div>
  );
};

export default Regioes;
