import React, { useState, useEffect } from "react";
import { ArrowcaLeftIcon, ArrowcaRightIcon, ThreeDotIcon } from "../data/Data";

const Pagination = ({ data, setShowData, length }) => {
  const [currentPage, setCurrentPage] = useState(1); // Start at page 1
  const totalPages = Math.ceil(data.length / length); // Calculate total number of pages

  useEffect(() => {
    // Update the sliced data based on the current page
    const startIndex = (currentPage - 1) * length;
    const endIndex = startIndex + length;
    setShowData(data.slice(startIndex, endIndex));
  }, [currentPage, data, length, setShowData]);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // Function to handle page clicks
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  // Render the pagination numbers dynamically
  const renderPaginationNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 4; // Show maximum of 4 page numbers at a time

    // Calculate the start and end page numbers to display
    let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

    // Adjust if we are at the start or end of the page list
    if (endPage - startPage + 1 < maxPageNumbers) {
      startPage = Math.max(1, endPage - maxPageNumbers + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`px-2 ${i === currentPage ? "font-bold" : ""}`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }

    return (
      <>
        {startPage > 1 && (
          <>
            <button onClick={() => handlePageClick(1)}>1</button>
            <ThreeDotIcon size={14} color={"var(--textColor)"} />
          </>
        )}
        {pageNumbers}
        {endPage < totalPages && (
          <>
            <ThreeDotIcon size={14} color={"var(--textColor)"} />
            <button onClick={() => handlePageClick(totalPages)}>
              {totalPages}
            </button>
          </>
        )}
      </>
    );
  };

  return (
    <div>
      {data.length !== 0 && (
        <div className="flex justify-center items-center mt-4">
          <button
            onClick={handlePrev}
            disabled={currentPage === 1}
            className="px-2"
          >
            <ArrowcaLeftIcon
              size={12}
              color={currentPage === 1 ? "#D9D9D9" : "var(--textColor)"}
            />
          </button>
          <div className="flex btc text-sm">{renderPaginationNumbers()}</div>
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className="px-2"
          >
            <ArrowcaRightIcon
              size={14}
              color={
                currentPage === totalPages ? "#D9D9D9" : "var(--textColor)"
              }
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default Pagination;
