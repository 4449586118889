import React from "react";

const ErrorCard = ({ error }) => {
  return (
    <>
      {error && (
        <div className="text-xs theme mt-1">
          <div>{error}</div>
        </div>
      )}
    </>
  );
};

export default ErrorCard;
