import React from "react";
import To8s from "./To8s";
import { LocationIcon } from "../../data/Data";
import { Link } from "react-router-dom";

const RegionCard = ({
  title,
  dropdowns,
  dropdownId,
  toggleDropdown,
  options,
  handleSelect, 
  tags,
}) => {
  const Tagger = ({ tag, url}) => {
    return (
      <Link to={url}>
        <div className="btnbtn theme hover text-xs text-center p-1 py-2 rounded-2xl">
          {tag}
        </div>
      </Link>
    );
  };
  return (
    <div className="btnbtn21 p-5 rounded-xl mb-5">
      <div className="font-semibold text-xl mb-5">{title}</div>
      <div className="">
        <To8s
          svg={LocationIcon}
          placehoder={"Selecione a sua cidade"}
          value={"ranas"}
          dropdowns={dropdowns}
          dropdownId={dropdownId}
          toggleDropdown={toggleDropdown}
          options={options}
          handleSelect={handleSelect}
        />
      </div>

      <div className="border-top-brown py-3 my-2">
        <div className="text-sm font-medium btc">Ou selecione pelas regiões mais procuradas.</div>
        <div className="grid grid-cols-2 gap-x-5 gap-y-2 sm:gap-y-3 mt-3">
          {tags.map((tag, index) => (
            <Tagger tag={tag.name} url={tag.url} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RegionCard;
