import React from "react";
import Filter from "../../controller/Filter";
import ProfileCard from "../cards/ProfileCard";
import PageWait from "../general/PageWait";
import LoadError from "../general/LoadError";
import NothingYet from "../general/NothingYet";
import Pagination from "../../controller/Pagination";
import { calculateAge } from "../../controller/Functions";

const Section2 = ({
  isMobile,
  isActive,
  setisActive,
  loading,
  data,
  error,
  notFound,
  allData,
  setMyData,
}) => {

  return (
    <div className="my-5 mb-40 mx-5 lg:mx-40 md:mx-20">
      <div className="md:mx-10 md:mb-10">
        <Filter
          active={isActive}
          setactive={setisActive}
          allData={allData}
          setMyData={setMyData}
          loading={loading}
        />
      </div>
      {!isMobile && <div className="font-bold">Resultados</div>}
      {loading && <PageWait />}
      {error && !notFound && <LoadError />}
      {!loading && data.length > 0 && (
        <div className="my-5 sm:grid sm:grid-cols-1 lg:grid-cols-3 md:grid-cols-2">
          {data.map((d) => (
            <div key={d.id}>
              <ProfileCard
                who={d.d0}
                imgAds={d.d1}
                imgs={d.d2}
                vids={d.d3}
                plan={d.d4}
                name={d.d5}
                price={d.d6}
                rating={d.d7}
                age={calculateAge(d.d8)}
                city={d.d9}
                ownplace={d.d10}
                sex={d.d11}
                username={d.d12}
                followers={d.d13} 
                lastOnline={d.d14}
                iallow={d.d15}
              />
            </div>
          ))}
        </div>
      )}
      <div className="flex justify-center items-center my-5">
        {data.length >= 12 && <Pagination />}
      </div>
      {!loading && notFound && data.length === 0 && <NothingYet text={"Nenhuma acompanhante encontrada! Confira outras cidades"} title={'Nada aqui!'} />}
    </div>
  );
};

export default Section2;
