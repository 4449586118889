import React, { createContext, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { useData } from "../contexts/DataContext";
import { useAuth } from "../contexts/AuthContext";

const GeneralBrainContext = createContext();

export const GeneralBrainProvider = ({ children }) => {
  const {
    lastSeen,
    loading,
    userNickname,
    userName,
    userEmail,
    aboutMe,
    dateOfBirth,
    language,
    status,
    userId,
    userCountry,
    joinDate,
    isAdmin,
    ipAddress,
  } = useData();
  const { currentUser, userData, todo, settodo, showOverBoard, setShowOverBoard, setlogger, } = useAuth();
  const [who, setwho] = useState("");
  const [scroll, setscroll] = useState(true);
  const [dropdowns, setDropdowns] = useState(Array(18).fill(false));
  const [showOverlay, setShowOverlay] = useState(true);
  const [showFullPage, setShowFullPage] = useState(false);

  const [statusData, setStatusData] = useState([]);
  const [statusId, setStatusId] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [statusDocId, setStatusDocId] = useState("");

  const toggleDropdown = (index) => {
    const updatedDropdowns = [...dropdowns];
    updatedDropdowns[index] = !updatedDropdowns[index];

    for (let i = 0; i < updatedDropdowns.length; i++) {
      if (i !== index) {
        updatedDropdowns[i] = false;
      }
    }

    setDropdowns(updatedDropdowns);
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = () => {
      setIsMobile(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  const contextValue = {
    isMobile,
    who,
    setwho,
    toggleDropdown,
    dropdowns,
    setDropdowns,
    scroll,
    setscroll,
    showOverlay,
    setShowOverlay,
    showFullPage,
    setShowFullPage,
    statusData,
    setStatusData,
    statusId,
    setStatusId,
    statusList,
    setStatusList,
    statusDocId,
    setStatusDocId,
    todo,
    settodo,
    // ---------- Auth
    currentUser,
    userData,
    showOverBoard, 
    setShowOverBoard,
    setlogger,
    // ---------- Data
    lastSeen,
    loading,
    userNickname,
    userName,
    userEmail,
    aboutMe,
    dateOfBirth,
    language,
    status,
    userId,
    userCountry,
    joinDate,
    isAdmin,
    ipAddress,
  };

  return (
    <GeneralBrainContext.Provider value={contextValue}>
      {children}
    </GeneralBrainContext.Provider>
  );
};

export const useGeneralBrain = () => {
  const context = useContext(GeneralBrainContext);
  if (!context) {
    throw new Error(
      "useGeneralBrain must be used within a GeneralBrainProvider"
    );
  }
  return context;
};

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});

export const Modal = Swal.mixin({
  toast: false,
  position: "center",
  showConfirmButton: true,
  confirmButtonText: "Close",
  didOpen: (modal) => {},
});

export const showConfirmDialog = async ({ title, text, icon } = {}) => {
  const { isConfirmed } = await Swal.fire({
    title: title || "Are you sure?",
    text: text || "This action cannot be undone!",
    icon: icon || "warning",
    showCancelButton: true,
    confirmButtonColor: "black",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, confirm!",
  });
  return isConfirmed;
};

export const stater = "sao-paulo-sp";
