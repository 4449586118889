import React from "react";

const PageIsLoading = () => {
  return (
    <div className="loading-container ">
      <div>
        <div className="navbar-logo-img navbar-logo-imgs">
          <img src="/logo.svg" alt="logo" className="navbar-logo" />
        </div>
      </div>
    </div> 
  );
};

export default PageIsLoading;
