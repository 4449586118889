import React from "react";
import { EditIcon } from "../../data/Data";
import Overlay from "../general/Overlay";

const To14 = ({
  text,
  tc,
  size,
  data,
  setData,
  showOverlay,
  setShowOverlay,
  card: Card,
  sober,
  cancelNow,
  updateNow,
  handleChange,
  dropdowns,
  toggleDropdown,
  handleSelect,
  handleCheck,
  values,
  setvalues,
  exp,
  setexp,
  statesOptions,
  citiesOptions,
  noedit,
}) => {
  const isEdit = window.location.pathname === "/editar-perfil";

  return (
    <div>
      <div className="flex justify-between items-center">
        <div
          className={`my-2 font-semibold ${
            size === "lg" ? "text-lg mb-2" : "text-sm"
          } ${tc ? tc : "btc"}`}
        >
          {text} {sober && <span className="theme">{sober}</span>}
        </div>
        {isEdit && !noedit && (
          <div onClick={() => setShowOverlay(true)} className="hover">
            <EditIcon />
          </div>
        )}
      </div>
      {showOverlay && (
        <Overlay
          card={Card}
          data={data}
          setData={setData}
          setshowfilter={setShowOverlay}
          cancelNow={cancelNow}
          updateNow={updateNow}
          handleChange={handleChange}
          dropdowns={dropdowns}
          toggleDropdown={toggleDropdown}
          handleSelect={handleSelect}
          handleCheck={handleCheck}
          values={values}
          setvalues={setvalues}
          exp={exp}
          setexp={setexp}
          statesOptions={statesOptions}
          citiesOptions={citiesOptions}
        />
      )}
    </div>
  );
};

export default To14;
