import React from "react";
import { Slider } from "@mui/material";

const Sec1 = ({ title, t1, t2, s1, s2, value, onChange }) => {
  
  const marks = [
    {
      value: s1,
      label: "",
    },
    {
      value: s2,
      label: "",
    },
  ];

  return (
    <div className="mb-5">
      <div className="mt-2 text-sm font-normal">{title}</div>
      <div>
        <Slider
          value={value}
          onChange={onChange}
          track="inverted"
          aria-labelledby="track-inverted-range-slider"
          marks={marks}
          sx={{
            "& .MuiSlider-thumb": {
              color: "var(--themeColor)",
            },
            "& .MuiSlider-track": {
              color: "var(--themeColor)",
            },
            "& .MuiSlider-rail": {
              color: "#D9D9D9",
            },
            "& .MuiSlider-active": {
              color: "#000",
            },
          }}
        />
        <div className="flex justify-between items-center text-xs -mt-3 font-medium">
          <div>{t1}</div>
          <div>{t2}</div>
        </div>
      </div>
    </div>
  );
};

export default Sec1;
