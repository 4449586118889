import React from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const To8p = ({ value, setValue, placehoder }) => {
  return (
    <div className="btnbtn21 p-1 rounded-md mb-3">
      <PhoneInput
        placeholder={placehoder}
        international
        countryCallingCodeEditable={false}
        defaultCountry="BR"
        value={value}
        onChange={setValue}
        className="custom-phone-input"
      />
    </div>
  );
};

export default To8p;
