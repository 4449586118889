import React, { useEffect, useState } from "react";
import Section1 from "../../components/Acompanhantes/Section1";
import Section2 from "../../components/Acompanhantes/Section2";
import { useGeneralBrain } from "../../controller/Brain";
import { useFetchData } from "../../controller/Hooks";

const Acompanhantes = ({ query }) => {
  const { isMobile, setStatusDocId } = useGeneralBrain();
  const [myData, setMyData] = useState([]);
  const [allData, setAllData] = useState({});
  const [status, setStatus] = useState({});
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [isActive, setIsActive] = useState("escort-womens");

  const doc1 = `${query}-${isActive}`;
  const doc2 = `${query}-${isActive}-status`;

  const {
    data: fetchedData,
    loading: fetchLoading,
    error: error2,
    notFound: notFound2,
  } = useFetchData({
    cols: "lives",
    docId: doc1,
  });

  const {
    data: fetchedData1,
    loading: fetchLoading1, 
    error: error1,
    setData:setd,
    notFound: notFound1,
  } = useFetchData({
    cols: "lives",
    docId: doc2,
  });

  useEffect(() => {
    setLoading(true);
    setMyData([]);
    if (!fetchLoading) {
      setAllData(fetchedData);
      setLoading(fetchLoading); 
    }
  }, [fetchedData, fetchLoading]);

  useEffect(() => {
    setStatus({});
    setd(null)
    setLoading1(true);
    if (!fetchLoading1) {
      setStatusDocId(doc2)
      setStatus(fetchedData1);
      setLoading1(fetchLoading1); 
    }
    /* eslint-disable-next-line */
  }, [fetchedData1, fetchLoading1]);

  return (
    <div className="">
      <Section1
        isMobile={isMobile}
        error={error1}
        notFound={notFound1}
        loading={loading1} 
        data={status} // Status comes from `doc2`
      />
      <Section2
        isMobile={isMobile}
        isActive={isActive}
        setisActive={setIsActive}
        loading={loading}
        error={error2}
        notFound={notFound2}
        allData={allData} // Data from `doc1`
        setMyData={setMyData}
        data={myData}
      />
    </div>
  );
};

export default Acompanhantes;
