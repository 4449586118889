import React, { useEffect, useState } from "react";
import Board from "../../components/cards/Board";
import Filter from "../../controller/FilterPost";
import { useGeneralBrain } from "../../controller/Brain";
import PostCard from "../../components/cards/PostCard";
import { useFetchData } from "../../controller/Hooks";
import PageWait from "../../components/general/PageWait";
import LoadError from "../../components/general/LoadError";
import NothingYet from "../../components/general/NothingYet";
import BackTop from "../../components/general/BackTop";


const AcompanEncontre = ({ query }) => {
  const { isMobile, userData } = useGeneralBrain();

  const [myData, setMyData] = useState([]);
  const [myList, setMyList] = useState([]);

  const [allData, setAllData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isActive, setIsActive] = useState("escort-womens");

  const docId = `${query}-${isActive}-post`;
  const {
    data: fetchedData,
    loading: fetchLoading,
    error,
    notFound,
  } = useFetchData({
    cols: "lives",
    docId: docId,
  });

  const { data: fetchedData1, loading: fetchLoading1 } = useFetchData({
    cols: "lists",
    docId: userData?.userId,
  });

  useEffect(() => {
    setMyData([]);
    setLoading(true);
    if (!fetchLoading) {
      setAllData(fetchedData);
      setLoading(fetchLoading);
    }
  }, [fetchedData, fetchLoading]);

  useEffect(() => {
    setMyList([]);
    if (!fetchLoading1) {
      setMyList(fetchedData1?.posts || []);
    }
  }, [fetchedData1, fetchLoading1]);

  return (
    <>
      <div>
        <Board title={"Fotos e vídeos de acompanhantes"} my={true} />
        <div className="lg:flex  mx-5 my-5 sm:mx-20 md:mx-64 lg:mx-80">
          <div className="lg:w-11/12">
            <Filter
              active={isActive}
              setactive={setIsActive}
              allData={allData}
              setMyData={setMyData}
              loading={loading}
            />
            {!isMobile && <div className="font-bold">Resultados</div>}
            {loading && <PageWait />}
            {error && !notFound && <LoadError />}
            {!loading && notFound && myData.length === 0 && <NothingYet />}
            {!loading && myData.length > 0 && (
              <div className="my-5">
                {myData.map((post) => (
                  <PostCard
                    key={post.id}
                    data={post}
                    list={myList}
                    docId={docId}
                    setMyList={setMyList}
                    setMyData={setMyData}
                    myData={myData}
                  />
                ))}
                <div className="md:hidden">
                  {" "}
                  <BackTop />
                </div>
              </div>
            )}
          </div>
          {!loading && myData.length > 0 && (
            <div>
              <div className="hidden fixed mt-14 hover lg:flex top-3/4 right-32 lg:flex-col  lg:w-40 ">
                <BackTop />
                <div className="-mt-2 theme hover underline text-center font-medium text-sm ">
                  Voltar ao topo
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AcompanEncontre;
