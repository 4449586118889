import React, { useEffect, useRef, useState } from "react";
import ErrorCard from "../cards/ErrorCard";

const Codes = ({ 
  title,
  desc,
  onClick,
  value, // Holds the combined 4-digit code
  setValue, // Function to update the 4-digit code
  timer,
  setTimer,
  codeSent,
  error,
}) => {
  const inputRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  // Countdown timer effect
  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [timer, setTimer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  // Function to handle input change
  const handleInputChange = (e, index) => {
    const newValue = e.target.value;

    if (/^\d$/.test(newValue)) {
      // Check if input is a single digit number
      const codeArray = [...value]; // Copy the current code array
      codeArray[index] = newValue; // Replace the corresponding index with the new value
      setValue(codeArray.join("")); // Update the combined code

      // Move to the next input if current input is filled
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  // Function to handle backspace (move cursor to previous input)
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (!e.target.value && index > 0) {
        // If current input is empty and backspace is pressed, move to the previous input
        const codeArray = [...value]; // Copy the current code array
        codeArray[index - 1] = ""; // Clear the value in the previous input
        setValue(codeArray.join("")); // Update the combined code
        inputRefs.current[index - 1].focus();
      } else {
        // Clear current input if it has a value
        const codeArray = [...value];
        codeArray[index] = "";
        setValue(codeArray.join(""));
      }
    }
  };

  const handle = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  return (
    <div className="mb-3">
      <div className="">
        <div className="flex justify-between items-start">
          <div>
            <div className="text-xs font-bold">{title}</div>
            <div className="text-8">{desc} Enter 4-digit code:</div>
          </div>

          <button
            className="w-max text-10 text-center hover-bg btnbtn theme p-1 rounded-md"
            onClick={!loading && handle}
            disabled={codeSent && timer > 0 && !loading}
          >
            {codeSent && timer > 0
              ? `Resend ${formatTime(timer)}`
              : `${loading ? "Sending..." : `Send Code`}`}
          </button>
        </div>

        {/* Input boxes for the 4-digit code */}
        <div className="flex justify-center items-center my-3">
          {Array(4)
            .fill("")
            .map((_, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                className="code-box-input w-8 h-8"
                ref={(el) => (inputRefs.current[index] = el)} // Store input refs
                value={value[index] || ""}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            ))}
        </div>
      </div>
      {error && <ErrorCard error={error} />}
    </div>
  );
};

export default Codes;
