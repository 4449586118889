const Overlay = ({
  card: Card,
  setshowfilter,
  data,
  setData,
  id,
  filter,
  setfilter,
  handleCheck,
  cancelNow,
  updateNow,
  handleChange,
  dropdowns,
  toggleDropdown,
  handleSelect,
  values,
  setvalues,
  exp,
  setexp,
  statesOptions,
  citiesOptions,
}) => {
  return (
    <div id="overlay" className="">
      <div className="con-overlay"></div>
      <div
        className={`con-box m-5 sm:mx-20 my-5 max-h-consent overflow-hidden rounded-2xl`}
      >
        {Card && (
          <div className="bg overflow-y-auto w-full h-full">
            <Card
              id={id}
              setshowfilter={setshowfilter}
              data={data}
              setData={setData}
              filter={filter}
              setfilter={setfilter}
              cancelNow={cancelNow}
              updateNow={updateNow}
              handleChange={handleChange}
              dropdowns={dropdowns}
              toggleDropdown={toggleDropdown}
              handleSelect={handleSelect}
              handleCheck={handleCheck}
              values={values}
              setvalues={setvalues}
              exp={exp}
              setexp={setexp}
              statesOptions={statesOptions}
              citiesOptions={citiesOptions}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Overlay;
