import React, { useEffect, useState } from "react";
import { Filter1Icon, SearchIcon, SortIcon } from "../data/Data";
import BarNav from "../components/cards/BarNav";
import { LocationBtn } from "./components";
import ShowFilter from "../components/filter/ShowFilter";
import ShowOrder from "../components/filter/ShowOrder";
import Overlay from "../components/general/Overlay";
import Location from "./Location";
import { getCityFromUrl, getLastWord } from "./Functions";
import { useLocation } from "react-router-dom";
import { useGeneralBrain } from "./Brain";

const Filter = ({
  active,
  who,
  setactive,
  allData,
  setMyData,
  loading,
  error,
}) => {
  useEffect(() => {
    if (!loading && !error && allData) {
      const filteredWorks = Object.entries(allData).map(([dataId, data]) => ({
        ...data,
        id: dataId,
        d14: "8 minutes",
      }));
      setMyData(filteredWorks);
    }

    /* eslint-disable-next-line */
  }, [allData, error]);

  const { isMobile } = useGeneralBrain();
  const [showfilter, setshowfilter] = useState(false);
  const [showorder, setshoworder] = useState(false);

  const [order, setorder] = useState("");
  const [filter, setfilter] = useState({
    value: {
      start: 0,
      end: 999,
    },
    age: {
      start: 18,
      end: 60,
    },
    review: false,
    callNow: false,
    Peso: {
      start: 40,
      end: 160,
    },
    Altura: {
      start: 0.8,
      end: 2,
    },
    "Tamanho do pé": {
      start: 20,
      end: 48,
    },
    Etnia: {
      branco: false,
      caboclo: false,
      cafuzo: false,
      indegina: false,
      mestico: false,
      mulato: false,
      negro: false,
      oriental: false,
      pardo: false,
    },
    Cabelo: {
      loiro: false,
      moreno: false,
      ruivo: false,
      castanho: false,
      colorido: false,
      grisalho: false,
      "sem cabelo": false,
    },
    Tatuagens: {
      "sem tatuagens": false,
      "com tatuagens": false,
    },
    Piercings: {
      "sem piercings": false,
      "com piercings": false,
    },
    Silicone: {
      "sem silicone": false,
      "com silicone": false,
    },
    "Cor dos olhos": {
      azul: false,
      castanho: false,
      cinza: false,
      mel: false,
      verde: false,
      preto: false,
    },
    Fumante: {
      "não é fumante": false,
      "é fumante": false,
    },
    "Comportamento sexual": {
      activo: false,
      passivo: false,
    },
    Atende: {
      casais: false,
      homens: false,
      "homens trans": false,
      mulheres: false,
      "mulheres trans": false,
      "nao binario": false,
    },
    "Clientes em conjunto": {
      "1 cliente": false,
      "2 clientes": false,
      "3 clientes": false,
      "4 ou mais clientes": false,
    },
    Idioma: {
      portugues: false,
      alemao: false,
      espanhol: false,
      frances: false,
      ingles: false,
      italiano: false,
      outro: false,
    },
    "Método de pagamento": {
      pix: false,
      dinheiro: false,
      "cartão de crédito": false,
      "cartão de débito": false,
    },
    Local: {
      "tem local": true,
      "nao tem local": false,
    },
    Serviços: {
      "Faz sexo oral sem preservativo": false,
      "Recebe sexo anal com preservativo": false,
      "Recebe sexo vaginal com preservativo": false,
      "Faz striptease": false,
      "Faz masturbação": false,
      "Recebe masturbação": false,
      "Faz massagem tradicional": false,
      "Faz massagem tântrica": false,
      "Recebe massagem tradicional": false,
      "Recebe massagem tântrica": false,
      "Faz sexo oral com preservativo": false,
      "Recebe sexo oral com preservativo": false,
      "Faz sexo vaginal com preservativo": false,
      "Faz sexo anal com preservativo": false,
      "Faz sexo virtual": false,
      "Faz dupla penetração": false,
      "Recebe dupla penetração": false,
      "Faz tripla penetração": false,
      "Recebe tripla penetração": false,
      "Faz dominação": false,
      "Recebe dominação": false,
      "Faz roleplay": false,
      "Faz penetração com acessórios sexuais": false,
      "Recebe penetração com acessórios sexuais": false,
      "Faz beijo grego": false,
      "Recebe beijo grego": false,
      "Faz podolatria": false,
      "Recebe podolatria": false,
      "Faz bondage": false,
      "Recebe bondage": false,
      "Faz sadomasoquismo": false,
      "Recebe sadomasoquismo": false,
      "Faz fisting": false,
      "Recebe fisting": false,
      "Faz facefuck": false,
      "Recebe facefuck": false,
      "Faz quirofilia": false,
      "Faz squirt": false,
      "Faz chuva dourada": false,
      "Recebe chuva dourada": false,
      "Faz chuva marrom": false,
      "Recebe chuva marrom": false,
      "Faz beijo na boca": false,
      "Permite filmagem": false,
      Voyeurismo: false,
      "Faz trampling": false,
      "Recebe trampling": false,
      "Uso de roupas de fantasia/uniformes": false,
      "Utiliza acessórios eróticos": false,
      "Faz viagem": false,
    },
  });

  const handleClick2 = () => {
    setshowfilter(showfilter ? false : true);
    setshoworder(false);
  };

  const handleClick1 = () => {
    setshoworder(showorder ? false : true);
    setshowfilter(false);
  };

  const [showOverlay1, setShowOverlay1] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setShowOverlay1(false);
  }, [location.pathname]);

  return (
    <div className="">
      <div className="sm:flex sm:items-center sm:flex-row-reverse gap-5 sm:mt-5 ">
        <BarNav
          value={active}
          setValue={setactive}
          t1={"Mulheres"}
          v1={"escort-womens"}
          t2={"Homens"}
          v2={"escort-mens"}
          t3={"Trans"}
          v3={"escort-trans"}
        />
        <div
          onClick={() => setShowOverlay1(true)}
          className="bg sm:w-1/2 btnbtn21 flex gap-2 items-center my-4 py-1.5 px-2 rounded-md "
        >
          <SearchIcon size={20} color="#8593A3" />
          <input
            type="text"
            placeholder="Busque pela sua cidade ou serviço que procura"
            className="outline-none tc bg section2-width pl-1 text-xs"
          />
        </div>
      </div>
      <div className="md:w-1/2 relative md:flex md:justify-between items-start">
        {who !== "post" && (
          <div className="md:w-1/2 mb-5 md:mb-0 mr-3">
            <div className="w-full  tc font-semibold text-xs btnbtn21 rounded-md text-center grid grid-cols-2">
              <div
                onClick={handleClick1}
                className="flex p-2 justify-center items-center hover custome-bord"
              >
                <SortIcon />
                <div>
                  {order
                    ? `${isMobile ? order : getLastWord(order)}`
                    : "Ordenar"}
                </div>
              </div>
              <div
                onClick={handleClick2}
                className="flex p-2 justify-center items-center hover"
              >
                <Filter1Icon />
                <div>Filtrar</div>
              </div>
            </div>
          </div>
        )}
        <div className="md:w-1/2 md:mt-1  flex justify-start items-center ">
          <div onClick={() => setShowOverlay1(true)}>
            <LocationBtn text={getCityFromUrl()} />
          </div>
          {filter?.Local?.["tem local"] === true && (
            <div onClick={handleClick2}>
              <LocationBtn text={"Tem local"} />
            </div>
          )}
        </div>

        <div className="absolute  top-5 w-full">
          <div className="z-40 relative bg box-shadow rounded-lg w-full">
            {showorder && (
              <ShowOrder
                value={order}
                setValue={setorder}
                setshoworder={setshoworder}
              />
            )}
          </div>
        </div>
      </div>
      {showOverlay1 && (
        <Overlay card={Location} setshowfilter={setShowOverlay1} />
      )}
      {showfilter && (
        <Overlay
          card={ShowFilter}
          setshowfilter={setshowfilter}
          filter={filter}
          setfilter={setfilter}
        />
      )}
    </div>
  );
};

export default Filter;
