import React from "react";
import { CaretDown, CaretUp } from "../../data/Data";
import Sec1 from "../filter/Sec1";
import { SelectBtn } from "../../controller/components";

const To8sa = ({
  placehoder,
  dropdowns,
  dropdownId,
  toggleDropdown,
  options,
  handleSelect,
  filter,
  handleValueChange,
  isRangeInput,
  
}) => {

  return (
    <div className={`border-bottom-brown mt-3`}>
      <div
        className={`wm-dropdown-btn ${
          dropdowns[dropdownId] ? "wm-active" : ""
        }`}
        onClick={() => toggleDropdown(dropdownId)}
      >
        <div
          className={`flex justify-between tc text-xs bg rounded-lg items-center py-2`}
        >
          <div className="capitalize font-normal flex items-center">
            <span>{placehoder}</span>
          </div>
          {!dropdowns[dropdownId] ? (
            <CaretDown color={"var(--textColor)"} />
          ) : (
            <CaretUp color={"var(--textColor)"} />
          )}
        </div>
      </div>
      <div
        className={`wm-dropdown-containers rounded-lg ${
          dropdowns[dropdownId] ? "wm-show" : ""
        }`}
      >
        {isRangeInput ? (
          <div className="px-5 -my-1 overflow-hidden">
            <Sec1
              t1={`de ${filter?.[placehoder].start}`}
              t2={`ate + ${filter?.[placehoder].end}`}
              s1={0}
              s2={100}
              value={[filter[placehoder].start, filter[placehoder].end]}
              onChange={handleValueChange}
            />
          </div>
        ) : (
          <div className="btc py-2 overflow-y-auto max-h-72 flex flex-wrap gap-1">
            {options && (
              <>
                {Object.keys(options).map((optionKey) => (
                  <SelectBtn
                    key={optionKey}
                    text={optionKey}
                    selected={filter?.[placehoder][optionKey]}
                    onClick={() => handleSelect(optionKey, placehoder)}
                  />
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default To8sa;
